import React from "react"
import css from "classnames"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

// @ts-ignore
import styles from "../styles.module.scss"

interface Props {
  name: string;
  title: string;
  reverse: boolean;
  children: any;
}

export const EmployeeAna = (props: Props) => {
  return <StaticQuery
    query={graphql`
        query {
          image: file(relativePath: { eq: "ana_circle.png"}) {
            childImageSharp {
              fluid(maxWidth: 600 ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className={css(styles.employee, { [styles.reverse]: props.reverse })}>
          <div className={styles.imageContainer}>
            <Img className={styles.round} fluid={data.image.childImageSharp.fluid}  alt={"Imagen de Ana"}/>
          </div>
          <div className={styles.text}>
            <h2> {props.name} <span>{props.title}</span></h2>
            <div>
              {props.children}
            </div>
          </div>
        </div>
      )}}
  />}