import * as React from "react"
import Layout from "../components/layout"
// import { EmployeeJuli } from "../components/Equipo/Employee/Juli"

import { EmployeeAna} from "../components/Equipo/Employee/Ana"
import Values from "../components/Equipo/Value"
import CustomHelmet from "../components/CustomHelmet"

const Conocenos = () => {
  return (
    <Layout>
      <CustomHelmet
        title={"El equipo. Quienes somos Desde Malta"}
        description={"Quien te ayuda a escojer el mejor curso de ingles: el equipo de Desde Malta y nuestros valores"}
        link={"/conocenos/"}
      />
      <div>
        <Values/>
        <EmployeeAna name={"Ana Maria Cardona Ochoa"} title={"Founder"} reverse={false}>
          <><p>
            Mi nombre es Ana María Cardona, estudié derecho y tengo un máster en administración de negocios. En 2014, después de trabajar durante 7 años para grandes empresas en mi país, decidí viajar a Malta a cumplir con el objetivo de mejorar mi inglés.
          </p>
          <p>
            Fue de la pasión que puse en mi aprendizaje y de las ganas de mejorar mi inglés, que tuve la oportunidad de trabajar para una de las instituciones más importante de enseñanza de inglés en la isla; y a partir de allí comencé a vincularme de forma profesional con la asesoría en la planificación de viajes de estudios.
          </p>
            <p>Hoy con Desde Malta y después de la experiencia adquirida durante estos años, ofrecemos un servicio de consultoría en educación, que se enfoca en el perfil de cada estudiante y en diseñar experiencias que les permita alcanzar sus objetivos de aprendizaje.</p>
            <p>Soy una convencida de que el esfuerzo y la disciplina sumados a una buena asesoría, hacen la diferencia entre alcanzar, o no, un objetivo.</p>
          </>
        </EmployeeAna>
        {/*<EmployeeJuli name={"Juliana Cuervo"} title={"Educational Consultant"} reverse={true}>*/}
          {/*<><p>*/}
            {/*“Yo un día voy a viajar a Malta”... Fue la frase que me dije a mi misma cuando a mis 10 años y haciendo una tarea de geografía para el colegio, vi en una enciclopedia, una fotografía de un pequeño país en medio del mar que se llamaba así, “Malta”... Han pasado más de 20 años desde ese momento y durante todo este tiempo, mis ganas de conocer la isla siempre estuvieron conmigo, intactas; esto sumado a mi sueño de viajar por el mundo y a mi meta de estudiar inglés.*/}
          {/*</p>*/}
            {/*<p>*/}
              {/*Soy Juliana, Diseñadora Industrial, Magister en Estudios Humanísticos y para muchos “la profe” pues me dediqué a la docencia universitaria por 8 años de mi vida. A mis 32 años, con estabilidad laboral y a los ojos de muchos “ya muy grandecita para hacerlo”, decidí parar mi trabajo para estudiar inglés y para por fin, cumplir mi sueño... Preparé mi viaje de la mano de Desde Malta y desde comienzos de 2019, empecé felizmente a trabajar como parte de su equipo. Luego de culminar mi curso de inglés y de experimentar uno de los momentos más felices de mi vida, decidí quedarme acá; viviendo en Malta y trabajando en Desde Malta.*/}
            {/*</p>*/}
            {/*<p>*/}
              {/*De mi trabajo valoro todo el amor, profesionalismo, transparencia y neutralidad en cada proceso de planeación de un viaje. Además, estar en Malta hace que la información que como equipo brindamos, sea actual y veraz y que nuestro acompañamiento sea permanente. Pienso que estudiar una segunda lengua y vivir un tiempo en un país diferente al propio, es algo invaluable e inolvidable; y me satisface enormemente saber, que hago parte de un equipo que se compromete para dar forma al proyecto y al sueño de muchos... Sueño que yo también en mi momento cumplí, de la mano de Desde Malta!*/}
            {/*</p>*/}
          {/*</>*/}
        {/*</EmployeeJuli>*/}
      </div>
    </Layout>
  )
}

export default Conocenos;