import React from "react"

// @ts-ignore
import styles from "./styles.module.scss"


const Values = () => {
  return (
    <>
      <div className={styles.value}>
        <div className={styles.title}>
          <h1>Nuestra motivación</h1>
        </div>
        <section>
          En Desde Malta sabemos que el plan de estudiar en el exterior es una decisión muy importante, por eso, nuestra
          motivación es ayudar a los estudiantes a tomar este paso de la manera más óptima y eficiente, haciendo un
          acompañamiento antes, durante y después de su experiencia.
        </section>
      </div>
    </>
  )
}

export default Values